import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-end-user-computing',
  templateUrl: './end-user-computing.component.html',
  styleUrls: ['./end-user-computing.component.scss']
})
export class EndUserComputingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
