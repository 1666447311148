import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hyper-converged-infrastructure',
  templateUrl: './hyper-converged-infrastructure.component.html',
  styleUrls: ['./hyper-converged-infrastructure.component.scss']
})
export class HyperConvergedInfrastructureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
