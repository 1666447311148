import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-centre-building',
  templateUrl: './data-centre-building.component.html',
  styleUrls: ['./data-centre-building.component.scss']
})
export class DataCentreBuildingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
