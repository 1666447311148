import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-server-administration',
  templateUrl: './server-administration.component.html',
  styleUrls: ['./server-administration.component.scss']
})
export class ServerAdministrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
