import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-governance-risk-compliance',
  templateUrl: './governance-risk-compliance.component.html',
  styleUrls: ['./governance-risk-compliance.component.scss']
})
export class GovernanceRiskComplianceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
