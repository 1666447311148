import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-private-cloud',
  templateUrl: './private-cloud.component.html',
  styleUrls: ['./private-cloud.component.scss']
})
export class PrivateCloudComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
