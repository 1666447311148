import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-security-operations-automation',
  templateUrl: './security-operations-automation.component.html',
  styleUrls: ['./security-operations-automation.component.scss']
})
export class SecurityOperationsAutomationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
