import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-access-sontrol-surveillance',
  templateUrl: './access-sontrol-surveillance.component.html',
  styleUrls: ['./access-sontrol-surveillance.component.scss']
})
export class AccessSontrolSurveillanceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
