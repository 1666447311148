import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edr-xdr',
  templateUrl: './edr-xdr.component.html',
  styleUrls: ['./edr-xdr.component.scss']
})
export class EdrXdrComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
