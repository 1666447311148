import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dataSolutionAi';
  ngOnInit(){

  };
  toggle(){
    let datatoggler = document.getElementById('dataToggler');
    if (datatoggler.classList.contains('show')) {
      datatoggler.classList.remove("show");
    } else {
      datatoggler.classList.add("show");
    }
    

  }

}
