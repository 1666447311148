import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnterpriseServersComponent } from './enterprise-servers/enterprise-servers.component';
import { HomeComponent } from './home/home.component';
import { EnterpriseStorageComponent } from './enterprise-storage/enterprise-storage.component';
import { HighPerformanceComputingComponent } from './high-performance-computing/high-performance-computing.component';
import { VirtualizationComponent } from './virtualization/virtualization.component';
import { DataCentreBuildingComponent } from './data-centre-building/data-centre-building.component';
import { DisasterRecoveryComponent } from './disaster-recovery/disaster-recovery.component';
import { PrivateCloudComponent } from './private-cloud/private-cloud.component';
import { HyperConvergedInfrastructureComponent } from './hyper-converged-infrastructure/hyper-converged-infrastructure.component';
import { EndUserComputingComponent } from './end-user-computing/end-user-computing.component';
import { CampusWideNetworkingComponent } from './campus-wide-networking/campus-wide-networking.component';
import { WirelessNetworkComponent } from './wireless-network/wireless-network.component';
import { LanWanComponent } from './lan-wan/lan-wan.component';
import { VoipTelephonyComponent } from './voip-telephony/voip-telephony.component';
import { NextGenFirewallsComponent } from './next-gen-firewalls/next-gen-firewalls.component';
import { SoarComponent } from './soar/soar.component';
import { SiemComponent } from './siem/siem.component';
import { EdrXdrComponent } from './edr-xdr/edr-xdr.component';
import { NextgenIdentityAccessManagementComponent } from './nextgen-identity-access-management/nextgen-identity-access-management.component';
import { EndpointProtectionComponent } from './endpoint-protection/endpoint-protection.component';
import { NextgenSocComponent } from './nextgen-soc/nextgen-soc.component';
import { AccessSontrolSurveillanceComponent } from './access-sontrol-surveillance/access-sontrol-surveillance.component';
import { VulnerabilityAssessmentComponent } from './vulnerability-assessment/vulnerability-assessment.component';
import { InfrastructureManagementServicesComponent } from './infrastructure-management-services/infrastructure-management-services.component';
import { ManagedEnterpriseNetworkServicesComponent } from './managed-enterprise-network-services/managed-enterprise-network-services.component';
import { ManagedCyberSecurityServicesComponent } from './managed-cyber-security-services/managed-cyber-security-services.component';
import { DataCenterOperationsComponent } from './data-center-operations/data-center-operations.component';
import { ServerAdministrationComponent } from './server-administration/server-administration.component';
import { DeviceHardwareServiceComponent } from './device-hardware-service/device-hardware-service.component';
import { NocComponent } from './noc/noc.component';
import { NetworkTransformationServiceComponent } from './network-transformation-service/network-transformation-service.component';
import { WirelessWanNetworkingSupportServiceComponent } from './wireless-wan-networking-support-service/wireless-wan-networking-support-service.component';
import { CampusNetworkingSupportServiceComponent } from './campus-networking-support-service/campus-networking-support-service.component';
import { ManagedSocComponent } from './managed-soc/managed-soc.component';
import { SecurityOperationsAutomationComponent } from './security-operations-automation/security-operations-automation.component';
import { GovernanceRiskComplianceComponent } from './governance-risk-compliance/governance-risk-compliance.component';
import { CyberSecurityTrainingComponent } from './cyber-security-training/cyber-security-training.component';
import { AboutUsComponent } from './about-us/about-us.component';


const routes: Routes = [
  {
    path:"",
    component:HomeComponent
  },
{
  path:"enterpriseServers",
  component:EnterpriseServersComponent,
},
{
  path:"enterpriseStorage",
  component:EnterpriseStorageComponent,
},
{
  path:"highperFormanceComputing",
  component:HighPerformanceComputingComponent
},
{
  path:"virtualization",
  component:VirtualizationComponent
},
{
  path:"dataCentreBuilding",
  component:DataCentreBuildingComponent
},
{
  path:"disasterRecovery",
  component:DisasterRecoveryComponent
},
{
  path:"PrivateCloud",
  component:PrivateCloudComponent
},
{
  path:"HyperConvergedInfrastructure",
  component:HyperConvergedInfrastructureComponent
},
{
  path:"endUserComputing",
  component:EndUserComputingComponent
},
{
  path:"campusWideNetworking",
  component:CampusWideNetworkingComponent
},
{
  path:"wirelessNetwork",
  component:WirelessNetworkComponent
},
{
  path:"lan&Wan",
  component:LanWanComponent
},
{
  path:"voipTelephony",
  component:VoipTelephonyComponent
},
{
  path:"nextGenFirewalls",
  component:NextGenFirewallsComponent
},
{
  path:"soar",
  component:SoarComponent
},
{
  path:"siem",
  component:SiemComponent
},
{
  path:"edrxdr",
  component:EdrXdrComponent
},
{
  path:"nextgenIdentityAccessManagement",
  component:NextgenIdentityAccessManagementComponent
},
{
  path:"endpointProtection",
  component:EndpointProtectionComponent
},
{
  path:"nextgenSOC",
  component:NextgenSocComponent
},
{
  path:"accessControlSurveillance",
  component:AccessSontrolSurveillanceComponent
},
{
  path:"vulnerabilityAssessment",
  component:VulnerabilityAssessmentComponent
},
{
  path:"InfrastructureManagementServices",
  component:InfrastructureManagementServicesComponent
},
{
  path:"ManagedEnterpriseNetworkServices",
  component:ManagedEnterpriseNetworkServicesComponent
},
{
  path:"ManagedCyberSecurityServices",
  component:ManagedCyberSecurityServicesComponent
},
{
  path:"dataCenterOperations",
  component:DataCenterOperationsComponent
},
{
  path:"serverAdministration",
  component:ServerAdministrationComponent
},
{
  path:"deviceHardwareService",
  component:DeviceHardwareServiceComponent
},
{
  path:"noc",
  component:NocComponent
},
{
  path:"networkTransformationService",
  component:NetworkTransformationServiceComponent
},
{
  path:"wirelessNetworkingSupportService",
  component:WirelessWanNetworkingSupportServiceComponent
},
{
  path:"campusNetworkingSupportService",
  component:CampusNetworkingSupportServiceComponent
},
{
  path:"managedSOC",
  component:ManagedSocComponent
},
{
  path:"SecurityOperationsAutomation",
  component:SecurityOperationsAutomationComponent
},
{
  path:"GovernanceRiskCompliance",
  component:GovernanceRiskComplianceComponent
},
{
  path:"cyberSecurityTraining",
  component:CyberSecurityTrainingComponent
},
{
  path:"home",
  component:HomeComponent
},
{
  path:"aboutus",
  component:AboutUsComponent
},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
