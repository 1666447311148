import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EnterpriseServersComponent } from './enterprise-servers/enterprise-servers.component';
import { HomeComponent } from './home/home.component';
import { EnterpriseStorageComponent } from './enterprise-storage/enterprise-storage.component';
import { HighPerformanceComputingComponent } from './high-performance-computing/high-performance-computing.component';
import { VirtualizationComponent } from './virtualization/virtualization.component';
import { DataCentreBuildingComponent } from './data-centre-building/data-centre-building.component';
import { DisasterRecoveryComponent } from './disaster-recovery/disaster-recovery.component';
import { PrivateCloudComponent } from './private-cloud/private-cloud.component';
import { HyperConvergedInfrastructureComponent } from './hyper-converged-infrastructure/hyper-converged-infrastructure.component';
import { EndUserComputingComponent } from './end-user-computing/end-user-computing.component';
import { CampusWideNetworkingComponent } from './campus-wide-networking/campus-wide-networking.component';
import { WirelessNetworkComponent } from './wireless-network/wireless-network.component';
import { LanWanComponent } from './lan-wan/lan-wan.component';
import { VoipTelephonyComponent } from './voip-telephony/voip-telephony.component';
import { NextGenFirewallsComponent } from './next-gen-firewalls/next-gen-firewalls.component';
import { SoarComponent } from './soar/soar.component';
import { SiemComponent } from './siem/siem.component';
import { EdrXdrComponent } from './edr-xdr/edr-xdr.component';
import { NextgenIdentityAccessManagementComponent } from './nextgen-identity-access-management/nextgen-identity-access-management.component';
import { EndpointProtectionComponent } from './endpoint-protection/endpoint-protection.component';
import { NextgenSocComponent } from './nextgen-soc/nextgen-soc.component';
import { AccessSontrolSurveillanceComponent } from './access-sontrol-surveillance/access-sontrol-surveillance.component';
import { VulnerabilityAssessmentComponent } from './vulnerability-assessment/vulnerability-assessment.component';
import { InfrastructureManagementServicesComponent } from './infrastructure-management-services/infrastructure-management-services.component';
import { ManagedEnterpriseNetworkServicesComponent } from './managed-enterprise-network-services/managed-enterprise-network-services.component';
import { ManagedCyberSecurityServicesComponent } from './managed-cyber-security-services/managed-cyber-security-services.component';
import { DataCenterOperationsComponent } from './data-center-operations/data-center-operations.component';
import { ServerAdministrationComponent } from './server-administration/server-administration.component';
import { DeviceHardwareServiceComponent } from './device-hardware-service/device-hardware-service.component';
import { NocComponent } from './noc/noc.component';
import { NetworkTransformationServiceComponent } from './network-transformation-service/network-transformation-service.component';
import { WirelessWanNetworkingSupportServiceComponent } from './wireless-wan-networking-support-service/wireless-wan-networking-support-service.component';
import { CampusNetworkingSupportServiceComponent } from './campus-networking-support-service/campus-networking-support-service.component';
import { ManagedSocComponent } from './managed-soc/managed-soc.component';
import { SecurityOperationsAutomationComponent } from './security-operations-automation/security-operations-automation.component';
import { GovernanceRiskComplianceComponent } from './governance-risk-compliance/governance-risk-compliance.component';
import { CyberSecurityTrainingComponent } from './cyber-security-training/cyber-security-training.component';
import { AboutUsComponent } from './about-us/about-us.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EnterpriseServersComponent,
    EnterpriseStorageComponent,
    HighPerformanceComputingComponent,
    VirtualizationComponent,
    DataCentreBuildingComponent,
    DisasterRecoveryComponent,
    PrivateCloudComponent,
    HyperConvergedInfrastructureComponent,
    EndUserComputingComponent,
    CampusWideNetworkingComponent,
    WirelessNetworkComponent,
    LanWanComponent,
    VoipTelephonyComponent,
    NextGenFirewallsComponent,
    SoarComponent,
    SiemComponent,
    EdrXdrComponent,
    NextgenIdentityAccessManagementComponent,
    EndpointProtectionComponent,
    NextgenSocComponent,
    AccessSontrolSurveillanceComponent,
    VulnerabilityAssessmentComponent,
    InfrastructureManagementServicesComponent,
    ManagedEnterpriseNetworkServicesComponent,
    ManagedCyberSecurityServicesComponent,
    DataCenterOperationsComponent,
    ServerAdministrationComponent,
    DeviceHardwareServiceComponent,
    NocComponent,
    NetworkTransformationServiceComponent,
    WirelessWanNetworkingSupportServiceComponent,
    CampusNetworkingSupportServiceComponent,
    ManagedSocComponent,
    SecurityOperationsAutomationComponent,
    GovernanceRiskComplianceComponent,
    CyberSecurityTrainingComponent,
    AboutUsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
