import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-hardware-service',
  templateUrl: './device-hardware-service.component.html',
  styleUrls: ['./device-hardware-service.component.scss']
})
export class DeviceHardwareServiceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
