import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cyber-security-training',
  templateUrl: './cyber-security-training.component.html',
  styleUrls: ['./cyber-security-training.component.scss']
})
export class CyberSecurityTrainingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
