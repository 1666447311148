import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nextgen-soc',
  templateUrl: './nextgen-soc.component.html',
  styleUrls: ['./nextgen-soc.component.scss']
})
export class NextgenSocComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
