import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-managed-cyber-security-services',
  templateUrl: './managed-cyber-security-services.component.html',
  styleUrls: ['./managed-cyber-security-services.component.scss']
})
export class ManagedCyberSecurityServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
